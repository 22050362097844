import Sidebar from "./sidebar";
import { useState } from "react";

export default function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <div className="flex gap-4 pb-5">
      <Sidebar sidebarOpen={true} setSidebarOpen={() => {}} />

      <div className="ml-48 p-2 relative flex flex-col flex-1 min-w-[1024px]">
        {/* <Header sidebarOpen={true} setSidebarOpen={() => {}} /> */}

        {children}
      </div>
    </div>
  );
}
