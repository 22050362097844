import moment from "moment";
import resolveConfig from "tailwindcss/resolveConfig";

export const fetcher = (...args) => fetch(...args).then((res) => res.json());

export const tailwindConfig = () => {
  return resolveConfig("./tailwind.config.js");
};

export const hexToRGB = (h) => {
  let r = 0;
  let g = 0;
  let b = 0;
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`;
    g = `0x${h[2]}${h[2]}`;
    b = `0x${h[3]}${h[3]}`;
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`;
    g = `0x${h[3]}${h[4]}`;
    b = `0x${h[5]}${h[6]}`;
  }
  return `${+r},${+g},${+b}`;
};

export const colourStyles = {
  menu: (styles) => ({ ...styles, width: "max-content", minWidth: "100%" }),
  valueContainer: (styles) => ({
    ...styles,
    padding: "0 5px",
    maxWidth: "120px",
  }),
  clearIndicator: (styles) => ({ ...styles, padding: "2px" }),
  dropdownIndicator: (styles) => ({ ...styles, padding: "2px" }),
  control: (styles) => ({
    ...styles,
    fontSize: "12px",
    border: "0",
    boxShadow: "none",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "12px",
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? tailwindConfig().theme.backgroundColor.purple[400]
        : isFocused
        ? tailwindConfig().theme.backgroundColor.purple[100]
        : null,
      color: isDisabled ? "#ccc" : isSelected ? "white" : "black",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  input: (styles) => ({
    ...styles,
    "input[type='text']:focus": {
      boxShadow: "none",
    },
  }),
};

export const formatValue = (value) =>
  Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const formatNumber = (value) => Intl.NumberFormat("en-GB").format(value);

export const formatThousands = (value) =>
  Intl.NumberFormat("en-GB", {
    maximumSignificantDigits: 3,
    notation: "compact",
  }).format(value);

export const formatCurrency = (value) =>
  Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
  }).format(value);

export const formatCurrencyDecimal = (value) =>
  Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 2,
  }).format(value);

export const formatPercentCalc = (value) =>
  Intl.NumberFormat("en-GB", {
    style: "percent",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);

export const formatPercent = (value) => `${Number(value).toFixed(2)}%`;
export const formatTimes = (value) => `${Number(value).toFixed(2)}x`;

export function setupQuery() {
  let dates = {};
  if (typeof window !== "undefined") {
    dates = JSON.parse(sessionStorage.getItem("dates")) || {};
  }
  const hasSavedDate = Object.keys(dates).length > 0;

  const objDates = {
    from_start: hasSavedDate
      ? moment(dates.from_start, "YYYY-MM-DD")
      : moment().subtract(14, "days"),
    from_end: hasSavedDate
      ? moment(dates.from_end)
      : moment().subtract(8, "days"),
    to_start: hasSavedDate
      ? moment(dates.to_start)
      : moment().subtract(7, "days"),
    to_end: hasSavedDate ? moment(dates.to_end) : moment().subtract(1, "days"),
  };

  return objDates;
}

export function setupDatepicker() {
  let dates = {};
  if (typeof window !== "undefined") {
    dates = JSON.parse(sessionStorage.getItem("dates")) || {};
  }
  const hasSavedDate = Object.keys(dates).length > 0;

  const objDates = {
    from: hasSavedDate
      ? moment(dates.to_start, "YYYY-MM-DD")
      : moment().subtract(14, "days"),
    to: hasSavedDate ? moment(dates.to_end) : moment().subtract(1, "days"),
  };

  return {
    from: objDates.from.toDate(),
    to: objDates.to.toDate(),
  };
}

export function humanize(str) {
  var i,
    frags = str.split("_");
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(" ");
}

export function getConditionalColor(max, min, value) {
  const colorOptions = [
    "bg-rose-50",
    "bg-rose-100",
    "bg-rose-200",
    "bg-rose-300",
    "bg-rose-400",
    "bg-rose-500 text-white",
    "bg-rose-600 text-white",
  ];

  const range = max - min;
  const step = range / (colorOptions.length - 1);
  const index = Math.floor((value - min) / step);
  return colorOptions[index];
}

export const conditionalStyles = [
  {
    when: (max, min, val) => getConditionalColorIndex(max, min, val) === 0,
    classNames: ["bg-rose-50"],
  },
  {
    when: (max, min, val) => getConditionalColorIndex(max, min, val) === 1,
    classNames: ["bg-rose-100"],
  },
  {
    when: (max, min, val) => getConditionalColorIndex(max, min, val) === 2,
    classNames: ["bg-rose-200"],
  },
  {
    when: (max, min, val) => getConditionalColorIndex(max, min, val) === 3,
    classNames: ["bg-rose-300"],
  },
  {
    when: (max, min, val) => getConditionalColorIndex(max, min, val) === 4,
    classNames: ["bg-rose-400"],
  },
  {
    when: (max, min, val) => getConditionalColorIndex(max, min, val) === 5,
    classNames: ["bg-rose-500 text-white"],
  },
  {
    when: (max, min, val) => getConditionalColorIndex(max, min, val) === 6,
    classNames: ["bg-rose-600 text-white"],
  },
];

export function getConditionalColorIndex(max, min, value) {
  const range = max - min;
  const step = range / 6;
  const index = Math.floor((value - min) / step);
  return index;
}
